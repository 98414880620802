/* FIXES
--------------------------------------------------------------------------------------------*/


//FORMS

input[type="radio" i] {
    padding:0 !important;
    margin:0 !important;
}

.gform_wrapper ul.gfield_checkbox li, .gform_wrapper ul.gfield_radio li {
    padding:0 !important;
    margin:0 !important;
    margin-right:10px !important;
    display:inline;
}

.gform_wrapper ul.gfield_checkbox, .gform_wrapper ul.gfield_radio {
    margin-top:-26px !important;
}

[data-s-type="hero"] .ac_hero_container {
    height: 75vh !important;
}

#opzegging-sectie-titel-3-3 .ac_content_container .ac_content_flex  {
    display:block !important;
}

//HEADERS

[data-s-type="hero-alt"] {
    position: relative;
    background: linear-gradient(
-135deg
,#de221e, #e97c02) !important;
    background-image: linear-gradient(
-135deg
, rgb(222, 34, 30), rgb(233, 124, 2)) !important;
    background-position-x: initial !important;
    background-position-y: initial !important;
    background-size: initial !important;
    background-repeat-x: initial !important;
    background-repeat-y: initial !important;
    background-attachment: initial !important;
    background-origin: initial !important;
    background-clip: initial !important;
    background-color: initial !important;

}

.ac_menu-2_logo_container{
        img {
        margin-top:-15px !important;
        max-width: 250% !important;
    }
}

.ac_menu-2_logo_img {
    width: 420px;
}

.ac_menu-2_sticky.is-stuck .ac_menu-2_logo_img {
    width: 200px;
    height: auto;
    margin-top:-5px !important;
}

.ac_menu-2_bar_top {
    background: none;
    a {
        color:$brand-secondary !important;
    }
}

@media screen and (max-width: 1100px){
    [data-s-type="hero"] .ac_hero_container {
        height: 60vh !important;
    }
    
}
.a_cover-cc {
    background-size: cover !important;
    background-position: 50% 30% !important;
}

.button.v_has-icon-left .svg-container {
    margin-right: 5px;
}

.button.v_has-icon-right .svg-container {
    margin-left: 5px;
}
[data-m-type="menu-2"] a {
    font-size: 11pt !important;
}
[data-m-type="menu-2"] a.button {
    font-size: 10pt !important;
    padding:0.4em;
}

[data-m-type="menu-2"] a:hover {
    
    color:$brand-primary;
}

[data-s-type="hero"] .ac_hero_slide_content_inner {
    justify-content: flex-start;
}


.ac_menu-2_cta_link {
    font-size: 20px !important;
}

[data-m-type="menu-2"] {
    a {
        color:$brand-black;
        font-weight: 600;
        font-size: 13px;
    }
}

[data-s-type="hero-alt"] .ac_hero_alt_container {
    height:200px
}

#breadcrumbs {
    display: none;
}

//test
.ac_hero_slide_content_text {
    display: none;
}

.ac_hero_slide {
    height:60vh;
}

.ac_hero_alt_slide {
   height:125px;
}
[data-s-type="hero"] .ac_hero_container {
    height:100% !important;
}

.slick-dots {
    bottom:40px !important;
}

.button:hover {
    color:#fff;
}

[data-s-amount="2"] .slick-slide [data-s-amount-item]{
    max-width: initial !important;
}

[data-s-type="hero-alt"] .ac_hero_alt_container {
    height:125px;
    min-height: 125px;
}

[data-s-type="hero-alt"] h1, [data-s-type="hero-alt"] h2, [data-s-type="hero-alt"] h3 {
    padding-top:20px;
}

[data-s-type="hero"] {
    height:60vh;
}

.ac_hero_slide_content_text {
    display: block;
    h2 {
        font-size: 34px !important;
        font-weight: bold;
        text-shadow: 2px 2px #5a5a5a;
        text-align: center;
    }
}


@media screen and (max-width: 1100px){
    .ac_menu-2_sticky.is-stuck .ac_menu-2_logo_img {
        width: 200px !important;
        height: auto;
        margin-top:10px !important;
    }

    .ac_menu-2_logo_img {
        width: 200px !important;
        height: auto;
        margin-top:10px !important;
    }
}

.ac_menu-2_logo_container + .ac_menu-2_main_content{
    margin-left: auto;
    margin-right: 5px;
}


// MENU MOBILE
@media screen and (max-width: 49.9375em){
    .ac_menu-2_sticky{
        display: block;
    }

    .ac_menu-2_sticky.is-stuck .ac_menu-2_logo_img {
        width: 150px !important;
        height: auto;
        margin-top:10px !important;
    }


    .ac_menu-2_main_content {
        padding-left:0px !important;
        margin-left:0px !important;
    }

    [data-s-type="hero"] .ac_hero_container {
        height: 50vh !important;
    }
    

    #menu-mobile{
        list-style: none;
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        li{
            display: block;
            width: 100%;
            text-align: center;
            margin: 0 0 5px 0;
            padding: 0 0 5px 0;
            border-bottom: 1px solid rgba(0,0,0,.1);
    
            a{
                text-align: center;
                display: block;
            }

            &:last-of-type{
                border-bottom: 0;
            }
        }

        .sub-menu{
            background: rgba(0,0,0,.1);
            padding: 10px 0;
            border-radius: 5px;
            li{
                border-bottom: 0;
            }
        }
    }
}

h2, h3, h4 {
    color: $brand-primary;
    padding-bottom:10px;
}

// GENERAL
.main-container [data-s-type="section-content"] a {
    text-decoration: underline;
    color: $brand-primary;
    &.button {
        color: $brand-white;
        text-decoration: none;
    }
}

.button {
    background: linear-gradient(
        -90deg, #2ea3f2, #143a73);
}

body {
    color:$brand-black;
}

//NEWS

[data-s-id="home-news"] .ac_item_image-container {
    display: none;
}
[data-s-type="news"] .ac_item_content_title {
    color: $brand-black;
}


// BRANDS

.ac_brands_item_container {
    border: none !important;
}

.ac_brands_item {
    border: none !important;
}

// SERVICES
[data-s-id="home-services"] {
    padding-bottom: 0px;
}

[data-s-type="services"] .ac_item_content_icon_container {
    display: none;
}

//FOOTER
[data-f-type="footer-1"] h2 {
    font-size: 160%;
    margin-bottom: 0px;
    opacity: .5;
}

[data-f-type="footer-1"] .ac_footer_primary {
    background: $brand-black;
}

[data-f-type="footer-1"] .ac_footer_primary_column:nth-child(4) {
    margin-left: -230px;
}

[data-f-type="footer-1"] .ac_footer_primary_container {
    padding: 20px 10px 5px 10px;
}

@media screen and ( max-width: 800px ){

    [data-f-type="footer-1"] .ac_footer_primary_column:nth-child(4) {
        margin-left: -12px;
    }
}

//CTA
.ac_cta_column {
    p {
        font-size: 18px;
        font-weight: 600;
    }
}

// IMAGES OVERLAY
[data-s-type="image-gallery_full-screen"] .slick-next svg, [data-s-type="image-gallery_full-screen"] .slick-prev svg {
    fill: $brand-primary;
}

li::marker {
    color:$brand-primary;
}

.ac_content_1-1 {
    padding-left: 10px;
    padding-right: 10px;
}

//Tables
tr {
    vertical-align: top;
}

td {
    padding-right: 10px;
}

//brands
.ac_brands_item_logo {
    -webkit-filter: none !important;
    filter: none !important;
}

//LI Services
@media screen and (max-width: 1200px){
    .mce-item-table {
        max-width: 600px;
    }
    
}

//LI Services
@media screen and (max-width: 600px){
    .mce-item-table {
        max-width: 300px;
    }
    
}

.mce-item-table {
    width: 1000px;
}

.mce-item-table ul {
    list-style: none;
    margin-bottom: 0 !important;
}

.mce-item-table p {
    padding-left:30px;
}
  
.mce-item-table ul li:before {
    content: '✓' !important;
    color:green;
    margin-right:5px;
}


.mce-item-table ol {
    list-style: none;
}
  
.mce-item-table ol li:before {
    content: 'x' !important;
    color:red;
    margin-right:5px;
}